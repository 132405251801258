import React from 'react'
import Link from '../Link'
import { NavLink } from 'react-router-dom';
import { darken } from 'polished'
import styled from 'styled-components'
import LogoDark from '../../assets/syncswaplogo.png'
import Menu from '../Menu'
import Row, { RowFixed } from '../Row'
import { withRouter } from 'react-router-dom'

const HeaderFrame = styled.div`
  display: grid;
  grid-template-columns: 1fr 120px;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  top: 0;
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.8);
  padding: 1rem 30px;
  z-index: 2;
  box-sizing: border-box;
  background-position: 0 -100%;
  box-shadow: transparent 0 0 0 1px;
  background-size: 100% 200%;
  transition: background-position 0.1s ease 0s, box-shadow 0.1s ease 0s;
  background-blend-mode: hard-light;

  :after {
    content: "";
    position: absolute;
    width: 100%;
    background: inherit;
    backdrop-filter: blur(3px);
    background-color: #00000055;
    top: 0;
    right: 0;
    bottom: 0;
    box-shadow: 0 0 2px rgb(0 0 0 / 25%), inset 0 0 2px 0 white;
    overflow: hidden;
    z-index: -1;
    filter: blur(10px);
  }

  @media (max-width: 1080px) {
    grid-template-columns: 1fr;
    padding: 0.5rem 1rem;
    width: calc(100%);
    position: relative;
  }

  @media screen and (max-width: 500px) {
    padding: 0.5rem 1rem;
  }
`


const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: flex-end;

  @media (max-width: 1080px) {
    display: none;
  }
`

const HeaderRow = styled(RowFixed)`
  @media (max-width: 1080px) {
    width: 100%;
  }
`

const HeaderLinks = styled(Row)`
  justify-content: center;
  @media (max-width: 1080px) {
    padding: 0.5rem;
    justify-content: flex-end;
  } ;
`

const Title = styled(Link)`
  display: flex;
  align-items: center;
  pointer-events: auto;
  justify-self: flex-start;
  margin-right: 12px;
  :hover {
    cursor: pointer;
  }
  @media screen and (max-width: 720px) {
    justify-self: center;
  }
`

const UniIcon = styled.div`
  transition: transform 0.3s ease;
  :hover {
    transform: rotate(-5deg);
  }
`

const activeClassName = 'ACTIVE'

const StyledLink = styled(NavLink).attrs({
  activeClassName,
})`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.text3};
  font-size: 1rem;
  width: fit-content;
  margin: 0 6px;
  padding: 8px 12px;
  font-weight: 500;

  &.${activeClassName} {
    border-radius: 12px;
    background-color: ${({ theme }) => theme.bg2};
    color: ${({ theme }) => theme.text1};
  }

  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.text1)};
  }
`

export const StyledMenuButton = styled.button`
  position: relative;
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  height: 35px;
  background-color: ${({ theme }) => theme.bg3};
  margin-left: 8px;
  padding: 0.15rem 0.5rem;
  border-radius: 0.5rem;

  :hover,
  :focus {
    cursor: pointer;
    outline: none;
    background-color: ${({ theme }) => theme.bg4};
  }

  svg {
    margin-top: 2px;
  }

  > * {
    stroke: ${({ theme }) => theme.text1};
  }
`

const SmallContentGrouping = styled.div`
  width: 100%;
  display: none;
  @media (max-width: 1080px) {
    display: initial;
  }
`


function Header({ history }) {
  return (
    <HeaderFrame>
      <HeaderRow>
        <Title to={'/'}>
          <UniIcon>
            <img width={'24px'} src={LogoDark} alt="logo" />
          </UniIcon>
        </Title>
        <HeaderLinks>
          <StyledLink
            to="/home"
            isActive={(match, { pathname }) => pathname === '/home'}
          >
            Overview
          </StyledLink>
          <StyledLink
            to="/tokens"
            isActive={(match, { pathname }) => {
              return (
                pathname.split('/')[1] === 'tokens' ||
                pathname.split('/')[1] === 'token'
              );
            }}
          >
            Tokens
          </StyledLink>
          <StyledLink
            to={'/pairs'}
            isActive={(match, { pathname }) => {
              return (
                pathname.split('/')[1] === 'pairs' ||
                pathname.split('/')[1] === 'pair'
              );
            }}
          >
            Pools
          </StyledLink>
          <StyledLink
            to={'/accounts'}
            isActive={(match, { pathname }) => {
              return (
                pathname.split('/')[1] === 'accounts' ||
                pathname.split('/')[1] === 'account'
              );
            }}
          >
            Wallets
          </StyledLink>
        </HeaderLinks>
      </HeaderRow>
      <HeaderControls>
        <Menu />
      </HeaderControls>
      <SmallContentGrouping>
        <Row gap="sm" justify="flex-end">
          <Menu />
        </Row>
      </SmallContentGrouping>
    </HeaderFrame>
  )
}

export default withRouter(Header)