interface Ticker {
    time: number;
    task: () => void;
}

/**
 * A helper class for creating scheduled tasks
 */
export abstract class Scheduler {
    /**
     * Run task immediately and schedule it in an invterval
     * @param task
     * @param interval
     */
    public static runAndSchedule(task: () => void, interval: number): number {
        task();
        return window.setInterval(task, interval);
    }

    public static schedule(task: () => void, interval: number): number {
        return window.setInterval(task, interval);
    }

    private static tickers: Map<string, Ticker> = new Map();

    public static runTicker(ticketId: string, task: () => void, delay: number) {
        const time: number = Date.now();
        this.tickers.set(ticketId, {
            time: time,
            task: task
        });
        setTimeout(() => {
            const ticker = this.tickers.get(ticketId);
            if (time === ticker?.time) {
                try {
                    ticker.task();
                } catch {
                }
            }
        }, delay);
    }
}
