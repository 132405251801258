import React, { useState } from 'react'
import styled from 'styled-components'
import { RowBetween, AutoRow } from '../Row'
import { useMedia } from 'react-use'
import { useGlobalData, useEthPrice } from '../../contexts/GlobalData'
import { formattedNum, localNumber } from '../../utils'

import EthPrice from '../UniPrice'
import { TYPE } from '../../Theme'
import { AutoColumn } from '../Column'

const Header = styled.div`
  width: 100%;
  position: sticky;
  top: 0;
  padding: 0.25rem;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.advancedBG};
`

const Medium = styled.span`
  font-weight: 500;
`

export default function GlobalStats() {
  //const below1295 = useMedia('(max-width: 1295px)')
  //const below1180 = useMedia('(max-width: 1180px)')
  //const below1024 = useMedia('(max-width: 1024px)')
  const below400 = useMedia('(max-width: 400px)')
  const below816 = useMedia('(max-width: 816px)')
  const below500 = useMedia('(max-width: 500px)')

  const [showPriceCard, setShowPriceCard] = useState(false)

  const { totalVolumeUSD, oneDayTxns, pairCount, feesValue } = useGlobalData()
  const [ethPrice] = useEthPrice()
  const formattedEthPrice = ethPrice ? formattedNum(ethPrice, true) : '-'
  const totalVolume = totalVolumeUSD ? '$' + totalVolumeUSD : ''
  const dailyFeesValue = feesValue ? '$' + feesValue : ''

  return (
    <Header style={{
      boxSizing: 'border-box',
    }}>
      <RowBetween style={{
        padding: below816 ? '1rem 1.4rem' : '1rem 1.4rem',
      }}>
        <AutoRow justifyContent='space-between'>
          {!below400 && (
            <AutoColumn gap='10px'>
              <TYPE.header
                onMouseEnter={() => {
                  setShowPriceCard(true)
                }}
                onMouseLeave={() => {
                  setShowPriceCard(false)
                }}
                style={{ position: 'relative' }}
              >
                Eth Price
              </TYPE.header>
              <TYPE.largeHeader mr={'1rem'}>
                <Medium>{formattedEthPrice}</Medium>&nbsp;
              </TYPE.largeHeader>
              {showPriceCard && <EthPrice />}
            </AutoColumn>
          )}

          <AutoColumn gap='10px'>
            <TYPE.header mr={'1rem'}>
              TVL
            </TYPE.header>
            <TYPE.largeHeader mr={'1rem'}>
              <Medium>{totalVolume}</Medium>&nbsp;
            </TYPE.largeHeader>
          </AutoColumn>

          {!below500 && (
            <AutoColumn gap='10px'>
              <TYPE.header mr={'1rem'}>
                Fees (24h)
              </TYPE.header>
              <TYPE.largeHeader mr={'1rem'}>
                <Medium>{dailyFeesValue}</Medium>&nbsp;
              </TYPE.largeHeader>
            </AutoColumn>
          )}

          {!below816 && (
            <AutoColumn gap='10px'>
              <TYPE.header mr={'1rem'}>
                Transactions (24h)
              </TYPE.header>
              <TYPE.largeHeader mr={'1rem'}>
                <Medium>{totalVolume ? localNumber(oneDayTxns) : ''}</Medium>&nbsp;
              </TYPE.largeHeader>
            </AutoColumn>
          )}
          {!below816 && (
            <AutoColumn gap='10px'>
              <TYPE.header mr={'1rem'}>
                Pools
              </TYPE.header>
              <TYPE.largeHeader mr={'1rem'}>
                <Medium>{totalVolume ? localNumber(pairCount) : ''}</Medium>&nbsp;
              </TYPE.largeHeader>
            </AutoColumn>
          )}
        </AutoRow>
      </RowBetween>
    </Header>
  )
}
