
export const BUNDLE_ID = '1'

export const timeframeOptions = {
  WEEK: '1 week',
  MONTH: '1 month',
  // THREE_MONTHS: '3 months',
  // YEAR: '1 year',
  HALF_YEAR: '6 months',
  ALL_TIME: 'All time',
}

// token list urls to fetch tokens from - use for warnings on tokens and pairs
export const SUPPORTED_LIST_URLS__NO_ENS = [
  /*
  'https://gateway.ipfs.io/ipns/tokens.syncswap.xyz',
  'https://www.coingecko.com/tokens_list/uniswap/defi_100/v_0_0_0.json',
  */
  'https://gist.githubusercontent.com/0xnakato/0a7d63c7ecde77ca1dc85d09111c0785/raw/55f0693318e74310123b2e64227599e2434d56a3/default.json'
]

// hide from overview list
export const TOKEN_BLACKLIST = [
]

// pair blacklist
export const PAIR_BLACKLIST = [
]

// warnings to display if page contains info about blocked token
export const BLOCKED_WARNINGS = {
}

/**
 * For tokens that cause erros on fee calculations
 */
export const FEE_WARNING_TOKENS = [

] // AMPL '0xd46ba6d942050d489dbd938a2c909a5d5039a161'

export const UNTRACKED_COPY = 'Derived USD values may be inaccurate without liquid stablecoin or ETH pairings.'

// pairs that should be tracked but arent due to lag in subgraph
export const TRACKED_OVERRIDES_PAIRS = [

]

// tokens that should be tracked but arent due to lag in subgraph
// all pairs that include token will be tracked
export const TRACKED_OVERRIDES_TOKENS = []