import React from 'react'
import styled, { keyframes } from 'styled-components'
import { TYPE, StyledLink } from '../../Theme'
import {
  useLatestBlocks,
  // useSessionStart
} from '../../contexts/Application'



const StyledPolling = styled.div`
  display: flex;
  color: white;
  margin-right: 1rem;
  border-radius: 4px;
  min-width: 135px;
  padding: 4px 6px;
  flex-wrap: no-wrap;
  background-color: ${({ theme }) => theme.bg2};
  transition: all 0.25s ease;
  color: ${({ theme }) => theme.green1};
  :hover {
    opacity: 1;
  }
  justify-content: space-between;
  z-index: 9999;

  @media screen and (max-width: 960px) {
    display: none;
  }
`
const StyledPollingDot = styled.div`
  width: 8px;
  height: 8px;
  min-height: 8px;
  min-width: 8px;
  margin-left: 0.4rem;
  margin-top: 3px;
  border-radius: 50%;
  position: relative;
  background-color: ${({ theme }) => theme.green1};
`

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const Spinner = styled.div`
  animation: ${rotate360} 1s cubic-bezier(0.83, 0, 0.17, 1) infinite;
  transform: translateZ(0);
  border-top: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 1px solid transparent;
  border-left: 2px solid ${({ theme }) => theme.green1};
  background: transparent;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  position: relative;
  left: -3px;
  top: -3px;
`


export default function Polling() {

  // const seconds = useSessionStart();
  const [lastestBlock] = useLatestBlocks();
  return (
    <StyledLink href={''}>
      <StyledPolling style={{ minWidth: !!lastestBlock ? 170 : 135 }}>
        <TYPE.small mr="4px" color={'#6C7284'} style={{ whiteSpace: 'nowrap' }}>
          Latest synced block {' '}
        </TYPE.small>
        <TYPE.small style={{ opacity: !!lastestBlock ? '0.6' : '0.8', whiteSpace: 'nowrap', marginLeft: 'auto' }}>{!!lastestBlock ? lastestBlock : ''}</TYPE.small>
        <StyledPollingDot>{!lastestBlock && <Spinner />}</StyledPollingDot>
        {/* <TYPE.small style={{ opacity: !!seconds ? '0.6' : '0.8', whiteSpace: 'nowrap', marginLeft: 'auto' }}>{!!seconds ? seconds + 's ago' : ''}</TYPE.small>
        <StyledPollingDot>{!seconds && <Spinner />}</StyledPollingDot> */}
      </StyledPolling>
    </StyledLink>
  )
}
