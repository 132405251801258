
export const EXPLORER_NAME = 'zkScan';
export const EXPLORER_LINK = 'explorer.zksync.io';
export const NETWORK_NAME = 'zkSync';

//export const DAI_PAIR = '0xa478c2975ab1ea89e8196811f51a7b7ade33eb11'.toLowerCase()
export const USDC_PAIR = '0x80115c708e12edd42e504c1cd52aea96c547c05c'.toLowerCase()
//export const USDT_PAIR = '0x0d4a11d5eeaac28ec3f61d100daf4d40471f1852'.toLowerCase()

export const SUBGRAPH_START_BLOCK = 1048736;

export const FACTORY_ADDRESS = '0xf2DAd89f2788a8CD54625C60b55cD3d2D0ACa7Cb'.toLowerCase()

export const WETH_ADDRESS = '0x5aea5775959fbc2557cc8789bc1bf90a239d9a91'.toLowerCase()

// export const DAI_ADDRESS = '0x076c66a0d5e757365d2ad814d94d4611ce783ea6'.toLowerCase()
export const USDC_ADDRESS = '0x3355df6d4c9c3035724fd0e3914de96a5a83aaf4'.toLowerCase()

export const START_TIMESTAMP = 1654012800 //1589760000