import React from 'react'
import styled from 'styled-components'
import { AutoRow, RowBetween, RowFixed } from '../Row'
import { TYPE } from '../../Theme'
import { useEthPrice } from '../../contexts/GlobalData'
import { formatDollarAmount } from '../../utils'
import Polling from './Polling'
import Link from '../Link'

const Wrapper = styled.div`
  width: 100%;
  padding: 10px 30px;
  box-sizing: border-box;
  background-color: #000;
`

const Item = styled(TYPE.main)`
  font-size: 12px;
`

const StyledLink = styled(Link)`
  font-size: 12px;
  color: ${({ theme }) => theme.text1};
`
const FadeInRow = styled(AutoRow)`
  opacity: ${props => (props.visible ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
`;

const TopBar = () => {
  const [ethPrices] = useEthPrice();
  return (
    <Wrapper>
      <RowBetween>
        <Polling />
        <FadeInRow gap="6px" visible={ethPrices}>
          <RowFixed>
            <Item fontSize="12">ETH Price</Item>
            <Item fontSize="12" fontWeight="700" ml="4px">
              {formatDollarAmount(ethPrices)}
            </Item>
          </RowFixed>
        </FadeInRow>

        <AutoRow gap="6px" style={{ justifyContent: 'flex-end' }}>
          {/*<StyledLink href="#">V2 Analytics</StyledLink>*/}
          <StyledLink href="https://syncswap.gitbook.io/syncswap/">Docs</StyledLink>
          <StyledLink href="https://syncswap.xyz/">App</StyledLink>
        </AutoRow>
      </RowBetween>
    </Wrapper>
  )
}

export default TopBar
