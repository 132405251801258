import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'

export const client = new ApolloClient({
  link: new HttpLink({
    uri: "https://api.studio.thegraph.com/query/30365/zksync-blocks/v0.0.9",
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})

export async function queryClient(options) {
  try {
    return await client.query(options);
  } catch (e) {
    console.error('queryClient: not success', options, e);
    return null;
  }
}

export async function queryBlockClient(options) {
  try {
    return await blockClient.query(options);
  } catch (e) {
    console.error('queryClient: not success', options, e);
    return null;
  }
}

export async function queryLocalClient(localClient, options) {
  try {
    return await localClient.query(options);
  } catch (e) {
    console.error('queryClient: not success', options, e);
    return null;
  }
}

export const healthClient = new ApolloClient({
  link: new HttpLink({
    uri: 'https://api.thegraph.com/index-node/graphql',
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})

/*
export const stakingClient = new ApolloClient({
  link: new HttpLink({
    uri: 'https://api.thegraph.com/subgraphs/name/way2rach/talisman',
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})
*/

export const blockClient = new ApolloClient({
  link: new HttpLink({
    // uri: 'https://api.thegraph.com/subgraphs/name/syncswap/zksync-blocks',
    uri: 'https://api.studio.thegraph.com/query/30365/zksync-blocks/v0.0.1'
  }),
  cache: new InMemoryCache(),
})
