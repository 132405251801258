import { BigNumber } from 'bignumber.js'
import { formatNumberByDecimals } from './index'
const ZERO = new BigNumber(0)
const ONE_DAY_SECONDS = new BigNumber(86400);
const YEAR_SECONDS = new BigNumber(31536000);

const DECIMALS_10 = new BigNumber(10).pow(10);

export function reviveFromJSON(key, value) {
    let result = value;
    if (
        (typeof value === 'object' && value !== null)
        && (value.hasOwnProperty('type'))) {
        switch (value.type) {
            case 'BigNumber':
                result = new BigNumber(value.hex);
                break;
            default:
                ;
        }
    }
    return result;
}

function getTimestampNow() {
    return Date.now() / 1000;
}

function getEpochStartNow(duration) {
    const timestamp = getTimestampNow();
    return timestamp - (timestamp % duration);
}



let isFetching = false;
let fetchPromise = null;

export async function apiFetchAllPools(
    network = 'zkSyncMainnet',
    account = '0x0000000000000000000000000000000000000080',
) {
    const dataKey = `poolsData-${network}-${account}`;
    const timestampKey = `poolsTimestamp-${network}-${account}`;

    const cachedData = localStorage.getItem(dataKey);
    const cachedTimestamp = localStorage.getItem(timestampKey);

    if (cachedData && cachedTimestamp && (Date.now() - Number(cachedTimestamp) < 60000)) {
        return JSON.parse(cachedData);
    }

    if (isFetching) {
        // If a request is already in progress, wait for it to complete
        await fetchPromise;
        return JSON.parse(localStorage.getItem(dataKey));
    }

    isFetching = true;
    fetchPromise = new Promise(async (resolve, reject) => {
        try {
            const response = await fetch(`https://api.syncswap.xyz/api/fetchers/fetchAllPools?network=${network}&account=${account}&quote=next`);
            if (response.status !== 200) {
                throw new Error("Request to fetch all pools resulted in an error");
            }


            const res = await response.text().then(res => JSON.parse(res, reviveFromJSON));


            const defaultTotalInfo = {
                totalTVL: ZERO,
                feesValue: ZERO,
                volume: ZERO,
                pairCount: 0
            }
            let pairTotalInfo = [
                // classic
                { ...defaultTotalInfo },
                // stable
                { ...defaultTotalInfo }
                // total
            ]
            const timestampNow = getTimestampNow();
            const epochDuration = res.epochDuration;
            const epochStart = getEpochStartNow(epochDuration);
            const lastEpochStart = getEpochStartNow(epochDuration) - epochDuration;
            const elapsedCurrentEpoch = Math.floor(timestampNow - epochStart);
            const elapsedSinceLastEpoch = epochDuration + elapsedCurrentEpoch;

            // Only show pools with $500 more liquidity
            const minLiqallowed = new BigNumber(500)
            res.pools = res.pools.filter(pool => {
                return pool.token0Value.plus(pool.token1Value).div(1e6).gte(minLiqallowed)
            })
            res.pools.forEach(pool => {
                pool.pool = pool.pool.toLowerCase();
                pool.id = pool.pool;
                pool.token0.id = pool.token0.token.toLowerCase();
                pool.token1.id = pool.token1.token.toLowerCase();
                pool.pairType = pool.poolType;
                if (!pool.totalSupply.isZero()) {
                    let feeStartTime = Number(pool.feeStartTime.toString());
                    if (feeStartTime < 1) {
                        feeStartTime = timestampNow;
                    }
                    const combined =
                        true || feeStartTime <= lastEpochStart
                            ? // combines two epochs
                            [
                                elapsedSinceLastEpoch,
                                pool.feesCurrentEpoch.plus(pool.feesLastEpoch),
                            ]
                            : feeStartTime <= epochStart
                                ? // use current epoch
                                [elapsedCurrentEpoch, pool.feesCurrentEpoch]
                                : // use part of current epoch
                                [timestampNow - feeStartTime, pool.feesCurrentEpoch];

                    const protocolFees = combined[1];

                    // recover LP fees with protocol fees and fee share.
                    const liquidityProviderFees = protocolFees
                        .multipliedBy(100000 - pool.protocolFee) // LP fee share, like 70000 or 70%
                        .div(pool.protocolFee); // protocol fee share, like 30000 or 30%

                    const totalFees = protocolFees.plus(liquidityProviderFees);

                    const noElapsedSecs = combined[0] < 1;
                    const elapsedSecs = noElapsedSecs
                        ? ZERO
                        : new BigNumber(Math.floor(combined[0]));

                    // 24h fees
                    const moreThanOneDay = elapsedSecs.gt(ONE_DAY_SECONDS);
                    const fees = moreThanOneDay
                        ? totalFees.multipliedBy(ONE_DAY_SECONDS).div(elapsedSecs)
                        : totalFees; // estimate 24h or less than 24h

                    const totalValue = pool.token0Value.plus(pool.token1Value);

                    // 24h volume
                    const avgFee = new BigNumber(
                        Math.floor((pool.swapFee01 + pool.swapFee10) / 2)
                    );
                    const volume =
                        avgFee.isZero() || noElapsedSecs
                            ? ZERO
                            : moreThanOneDay
                                ? totalFees
                                    .multipliedBy(totalValue)
                                    .multipliedBy(100000)
                                    .multipliedBy(ONE_DAY_SECONDS)
                                    .div(avgFee)
                                    .div(elapsedSecs)
                                    .div(pool.totalSupply)
                                : totalFees
                                    .multipliedBy(totalValue)
                                    .multipliedBy(100000)
                                    .div(avgFee)
                                    .div(pool.totalSupply);
                    // 24h apr, in 10 decimals
                    const apr = noElapsedSecs
                        ? ZERO
                        : liquidityProviderFees
                            .multipliedBy(DECIMALS_10)
                            .multipliedBy(YEAR_SECONDS)
                            .div(elapsedSecs)
                            .div(pool.totalSupply);


                    const feesValue = fees.multipliedBy(100000).multipliedBy(totalValue).div(pool.totalSupply);



                    pool.trackedReserveUSD = pool.token0Value.plus(pool.token1Value).toNumber();

                    pool.volume = volume;
                    pool.feesValue = feesValue.toNumber(); // value
                    pool.feeAPR = apr;

                    pool.reserve0 = pool.reserve0.div(new BigNumber(10).pow(pool.token0.decimals)).toNumber()
                    pool.reserve1 = pool.reserve1.div(new BigNumber(10).pow(pool.token1.decimals)).toNumber()
                    pool.volumeView = formatNumberByDecimals(volume, 6, 1);
                    pool.feesView = formatNumberByDecimals(feesValue, 6 + 5, 1);
                    pool.feeAPRView = formatNumberByDecimals(apr, 8, 2);
                    pool.liquidityView = formatNumberByDecimals(pool.trackedReserveUSD, 6, 1)

                    pairTotalInfo[pool.poolType - 1].totalTVL = pairTotalInfo[pool.poolType - 1].totalTVL.plus(totalValue);
                    pairTotalInfo[pool.poolType - 1].volume = pairTotalInfo[pool.poolType - 1].volume.plus(volume);
                    pairTotalInfo[pool.poolType - 1].feesValue = pairTotalInfo[pool.poolType - 1].feesValue.plus(feesValue);
                    pairTotalInfo[pool.poolType - 1].pairCount++;
                }

            });

            pairTotalInfo[2] = {
                totalVolumeUSD: formatNumberByDecimals(pairTotalInfo[0].totalTVL.plus(pairTotalInfo[1].totalTVL).toNumber(), 6, 1),
                feesValue: formatNumberByDecimals(pairTotalInfo[0].feesValue.plus(pairTotalInfo[1].feesValue).toNumber(), 11, 1),
                volume: formatNumberByDecimals(pairTotalInfo[0].volume.plus(pairTotalInfo[1].volume).toNumber(), 6, 1),
                pairCount: pairTotalInfo[0].pairCount + pairTotalInfo[1].pairCount
            }
            pairTotalInfo[0] = {
                totalVolumeUSD: formatNumberByDecimals(pairTotalInfo[0].totalTVL.toNumber(), 6, 1),
                feesValue: formatNumberByDecimals(pairTotalInfo[0].feesValue.toNumber(), 11, 1),
                volume: formatNumberByDecimals(pairTotalInfo[0].volume.toNumber(), 6, 1),
                pairCount: pairTotalInfo[0].pairCount
            }
            pairTotalInfo[1] = {
                totalVolumeUSD: formatNumberByDecimals(pairTotalInfo[1].totalTVL.toNumber(), 6, 1),
                feesValue: formatNumberByDecimals(pairTotalInfo[1].feesValue.toNumber(), 11, 1),
                volume: formatNumberByDecimals(pairTotalInfo[1].volume.toNumber(), 6, 1),
                pairCount: pairTotalInfo[1].pairCount
            }
            res.pairsTotalInfo = pairTotalInfo;


            localStorage.setItem(dataKey, JSON.stringify(res));
            localStorage.setItem(timestampKey, Date.now().toString());

            resolve(res);
        } catch (e) {
            reject(e);
        } finally {
            isFetching = false;
        }
    });

    return fetchPromise;
}
