import React, { createContext, useContext, useReducer, useMemo, useCallback, useEffect, useState } from 'react'
import { queryClient } from '../apollo/client'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useTimeframe } from './Application'
import {
  getPercentChange,
  getBlockFromTimestamp,
  getBlocksFromTimestamps,
  get2DayPercentChange,
  getTimeframe,
  findAnomalousLiquidityValues,
} from '../utils'
import {
  GLOBAL_DATA,
  GLOBAL_TXNS,
  GLOBAL_CHART,
  ETH_PRICE,
  // ALL_PAIRS,
  ALL_TOKENS,
  TOP_LPS_PER_PAIRS,
} from '../apollo/queries'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import { useAllPairData } from './PairData'
import { useTokenChartDataCombined, useTokenDataContext } from './TokenData'
import BigNumber from 'bignumber.js'
import { apiFetchAllPools } from '../utils/syncApi'
const UPDATE = 'UPDATE'
const UPDATE_TXNS = 'UPDATE_TXNS'
const UPDATE_CHART = 'UPDATE_CHART'
const UPDATE_ETH_PRICE = 'UPDATE_ETH_PRICE'
const ETH_PRICE_KEY = 'ETH_PRICE_KEY'
const UPDATE_ALL_PAIRS_IN_UNISWAP = 'UPDATE_ALL_PAIRS_IN_UNISWAPTE_TOP_PAIRS'
const UPDATE_ALL_TOKENS_IN_UNISWAP = 'UPDATE_ALL_TOKENS_IN_UNISWAP'
const UPDATE_TOP_LPS = 'UPDATE_TOP_LPS'

const offsetVolumes = [
]

// format dayjs with the libraries that we need
dayjs.extend(utc)
dayjs.extend(weekOfYear)

const GlobalDataContext = createContext()

function useGlobalDataContext() {
  return useContext(GlobalDataContext)
}

function reducer(state, { type, payload }) {
  switch (type) {
    case UPDATE: {
      const { data } = payload
      return {
        ...state,
        globalData: data,
      }
    }
    case 'UPDATE_POOL': {
      const { currentPool } = payload
      return {
        ...state,
        currentPool
      }
    }
    case UPDATE_TXNS: {
      const { transactions } = payload
      return {
        ...state,
        transactions,
      }
    }
    case UPDATE_CHART: {
      const { daily, weekly } = payload
      return {
        ...state,
        chartData: {
          daily,
          weekly,
        },
      }
    }
    case UPDATE_ETH_PRICE: {
      const { ethPrice, oneDayPrice, ethPriceChange } = payload
      return {
        [ETH_PRICE_KEY]: ethPrice,
        oneDayPrice,
        ethPriceChange,
      }
    }

    case UPDATE_ALL_PAIRS_IN_UNISWAP: {
      const { allPairs, pairsTotalInfo } = payload
      return {
        ...state,
        allPairs,
        pairsTotalInfo: pairsTotalInfo
      }
    }

    case UPDATE_ALL_TOKENS_IN_UNISWAP: {
      const { allTokens } = payload
      return {
        ...state,
        allTokens,
      }
    }

    case UPDATE_TOP_LPS: {
      const { topLps } = payload
      return {
        ...state,
        topLps,
      }
    }
    default: {
      throw Error(`Unexpected action type in DataContext reducer: '${type}'.`)
    }
  }
}

export default function Provider({ children }) {
  const [state, dispatch] = useReducer(reducer, {})
  const update = useCallback((data) => {
    dispatch({
      type: UPDATE,
      payload: {
        data,
      },
    })
  }, [])

  const updatePool = useCallback((currentPool) => {
    dispatch({
      type: 'UPDATE_POOL',
      payload: {
        currentPool,
      },
    })
  }, [])

  const updateTransactions = useCallback((transactions) => {
    dispatch({
      type: UPDATE_TXNS,
      payload: {
        transactions,
      },
    })
  }, [])

  const updateChart = useCallback((daily, weekly) => {
    dispatch({
      type: UPDATE_CHART,
      payload: {
        daily,
        weekly,
      },
    })
  }, [])

  const updateEthPrice = useCallback((ethPrice, oneDayPrice, ethPriceChange) => {
    dispatch({
      type: UPDATE_ETH_PRICE,
      payload: {
        ethPrice,
        oneDayPrice,
        ethPriceChange,
      },
    })
  }, [])

  const updateAllPairsInUniswap = useCallback(({ pools, pairsTotalInfo }) => {
    dispatch({
      type: UPDATE_ALL_PAIRS_IN_UNISWAP,
      payload: {
        allPairs: pools,
        pairsTotalInfo
      },
    })
  }, [])

  const updateAllTokensInUniswap = useCallback((allTokens) => {
    dispatch({
      type: UPDATE_ALL_TOKENS_IN_UNISWAP,
      payload: {
        allTokens,
      },
    })
  }, [])

  const updateTopLps = useCallback((topLps) => {
    dispatch({
      type: UPDATE_TOP_LPS,
      payload: {
        topLps,
      },
    })
  }, [])
  return (
    <GlobalDataContext.Provider
      value={useMemo(
        () => [
          state,
          {
            update,
            updatePool,
            updateTransactions,
            updateChart,
            updateEthPrice,
            updateTopLps,
            updateAllPairsInUniswap,
            updateAllTokensInUniswap,
          },
        ],
        [
          state,
          update,
          updatePool,
          updateTransactions,
          updateTopLps,
          updateChart,
          updateEthPrice,
          updateAllPairsInUniswap,
          updateAllTokensInUniswap,
        ]
      )}
    >
      {children}
    </GlobalDataContext.Provider>
  )
}

// sum the pool data and push to the end 
const mergePoolData = (dataList) => {
  let totalData = { ...dataList[0] }
  for (let key in dataList[1]) {
    if (key !== 'id' && key !== '__typename') {
      totalData[key] = new BigNumber(totalData[key]).plus(new BigNumber(dataList[1][key])).toNumber()
    }
  }
  delete totalData.id;
  dataList[2] = totalData;
}

/**
 * Gets all the global data for the overview page.
 * Needs current eth price and the old eth price to get
 * 24 hour USD changes.
 * @param {*} ethPrice
 * @param {*} oldEthPrice
 */
async function getGlobalData(ethPrice, oldEthPrice) {
  // data for each day , historic data used for % changes
  const utcCurrentTime = dayjs();
  const timeStamps = [
    utcCurrentTime.subtract(1, 'day').unix(),
    utcCurrentTime.subtract(2, 'day').unix(),
    utcCurrentTime.subtract(1, 'week').unix(),
    utcCurrentTime.subtract(2, 'week').unix()
  ];

  // get the blocks needed for time travel queries
  const blocks = await getBlocksFromTimestamps(timeStamps);

  // fetch the global data
  const globalResult = await queryClient({
    query: GLOBAL_DATA(),
    fetchPolicy: 'cache-first',
  });
  let data = globalResult.data.syncSwapFactories;
  if (data && data.length > 1) mergePoolData(data);

  // fetch the historical data
  const historicalResults = await Promise.all(blocks.map(block =>
    queryClient({
      query: GLOBAL_DATA(block?.number),
      fetchPolicy: 'cache-first',
    })
  ));

  const [oneDayData, twoDayData, oneWeekData, twoWeekData] = historicalResults.map(result => {
    const dayData = result ? result.data.syncSwapFactories : null;
    if (dayData && dayData.length > 1) mergePoolData(dayData);
    return dayData;
  });

  if (data.length) {
    data = data.map((pool, i) => {
      const [oneDayVolumeUSD, volumeChangeUSD] = get2DayPercentChange(
        pool.totalVolumeUSD,
        oneDayData[i]?.totalVolumeUSD || 0,
        twoDayData[i]?.totalVolumeUSD || 0
      );

      const [oneWeekVolume, weeklyVolumeChange] = get2DayPercentChange(
        pool.totalVolumeUSD,
        oneWeekData[i]?.totalVolumeUSD || 0,
        twoWeekData[i]?.totalVolumeUSD || 0
      );

      const [oneDayTxns, txnChange] = get2DayPercentChange(
        pool.txCount,
        oneDayData[i]?.txCount || 0,
        twoDayData[i]?.txCount || 0
      );

      pool.totalLiquidityUSD = pool.totalLiquidityETH * ethPrice;
      const liquidityChangeUSD = getPercentChange(
        pool.totalLiquidityETH * ethPrice,
        oneDayData[i]?.totalLiquidityETH ? oneDayData[i].totalLiquidityETH * oldEthPrice : 0
      );

      Object.assign(pool, {
        oneDayVolumeUSD,
        oneWeekVolume,
        weeklyVolumeChange,
        volumeChangeUSD,
        liquidityChangeUSD,
        oneDayTxns,
        txnChange
      });

      return pool;
    });
  }

  return data;
}

/**
 * Get historical data for volume and liquidity used in global charts
 * on main page
 * @param {*} oldestDateToFetch // start of window to fetch from
 */

let checked = false

const getChartData = async (oldestDateToFetch, offsetData) => {
  const CHART_DATA_KEY = 'CHART_DATA'
  let storageData = localStorage.getItem(CHART_DATA_KEY);
  let data = []
  if (storageData) {
    storageData = JSON.parse(storageData);
    if (storageData.length > 2) {
      storageData = storageData.slice(0, storageData.length - 1);
      oldestDateToFetch = storageData[storageData.length - 1].date;
      data = storageData;
    }
  }
  let weeklyData = []
  const utcEndTime = dayjs.utc()
  let skip = 0
  let allFound = false

  try {
    while (!allFound) {
      let result = await queryClient({
        query: GLOBAL_CHART,
        variables: {
          startTime: oldestDateToFetch,
          skip,
        },
        fetchPolicy: 'cache-first',
      })
      skip += 1000
      if (result) {
        const dateMap = new Map();
        result.data.dayDatas.forEach((item, index) => dateMap.set(item.date, index));
        const AnomalousLiquidityValues = findAnomalousLiquidityValues(result.data.dayDatas);
        const ALValues = AnomalousLiquidityValues;
        const ALVDates = ALValues.map(item => item.date);
        const ALVBlocks = await getBlocksFromTimestamps(ALVDates);

        await Promise.all(ALVBlocks.map(item =>
          queryClient({
            query: GLOBAL_DATA(item.number),
            fetchPolicy: 'cache-first',
          })
        )).then(res => {
          res.forEach((item, index) => {
            if (item && item.data?.syncSwapFactories?.length === 2) {
              const [pair1, pair2] = item.data.syncSwapFactories;
              const idx = dateMap.get(AnomalousLiquidityValues[index].date);
              // const prevData = ALValues[idx - 1] || {};
              // ALValues[idx].dailyVolumeETH = pair1.totalVolumeETH * 1 + pair2.totalVolumeETH * 1 - (prevData.totalVolumeETH || 0);
              // ALValues[idx].dailyVolumeUSD = pair1.totalVolumeUSD * 1 + pair2.totalVolumeUSD * 1 - (prevData.totalVolumeUSD || 0);
              // ALValues[idx].totalVolumeUSD = pair1.totalVolumeUSD * 1 + pair2.totalVolumeUSD * 1;
              // ALValues[idx].totalVolumeETH = pair1.totalVolumeETH * 1 + pair2.totalVolumeETH * 1;
              result.data.dayDatas[idx].totalLiquidityETH = pair1.totalLiquidityETH * 1 + pair2.totalLiquidityETH * 1;
              result.data.dayDatas[idx].totalLiquidityUSD = pair1.totalLiquidityUSD * 1 + pair2.totalLiquidityUSD * 1;
            }
          });
        });
        data = data.concat(result.data.dayDatas);

      }
      if (!result || result.data.dayDatas.length < 1000) {
        allFound = true
      }
    }

    if (data && data.length !== 0) {
      localStorage.setItem(CHART_DATA_KEY, JSON.stringify(data))
      let dayIndexSet = new Set()
      let dayIndexArray = []
      const oneDay = 24 * 60 * 60

      // for each day, parse the daily volume and format for chart array
      data.forEach((dayData, i) => {
        if (!dayData || !data[i] || !data[i].date) {
          return;
        }
        // add the day index to the set of days
        dayIndexSet.add((data[i].date / oneDay).toFixed(0))
        dayIndexArray.push(data[i])
        dayData.dailyVolumeUSD = parseFloat(dayData.dailyVolumeUSD)
      })

      // fill in empty days ( there will be no day datas if no trades made that day )
      let timestamp = data[0].date ? data[0].date : oldestDateToFetch
      let latestLiquidityUSD = data[0].totalLiquidityUSD
      let latestDayDats = data[0].mostLiquidTokens
      let index = 1
      while (timestamp < utcEndTime.unix() - oneDay) {
        const nextDay = timestamp + oneDay
        let currentDayIndex = (nextDay / oneDay).toFixed(0)

        if (!dayIndexSet.has(currentDayIndex)) {
          data.push({
            date: nextDay,
            dailyVolumeUSD: 0,
            totalLiquidityUSD: latestLiquidityUSD,
            mostLiquidTokens: latestDayDats,
          })
        } else {
          latestLiquidityUSD = dayIndexArray[index].totalLiquidityUSD
          latestDayDats = dayIndexArray[index].mostLiquidTokens
          index = index + 1
        }
        timestamp = nextDay
      }
    }

    // format weekly data for weekly sized chunks
    data = data.sort((a, b) => (parseInt(a.date) > parseInt(b.date) ? 1 : -1))
    let startIndexWeekly = -1
    let currentWeek = -1

    data.forEach((entry, i) => {
      const date = data[i].date

      // hardcoded fix for offset volume
      offsetData &&
        !checked &&
        offsetData.map((dayData) => {
          if (dayData[date]) {
            data[i].dailyVolumeUSD = parseFloat(data[i].dailyVolumeUSD) - parseFloat(dayData[date].dailyVolumeUSD)
          }
          return true
        })

      const week = dayjs.utc(dayjs.unix(data[i].date)).week()
      if (week !== currentWeek) {
        currentWeek = week
        startIndexWeekly++
      }
      weeklyData[startIndexWeekly] = weeklyData[startIndexWeekly] || {}
      weeklyData[startIndexWeekly].date = data[i].date
      weeklyData[startIndexWeekly].weeklyVolumeUSD =
        (weeklyData[startIndexWeekly].weeklyVolumeUSD ?? 0) + data[i].dailyVolumeUSD
    })

    if (!checked) {
      checked = true
    }
  } catch (e) {
    console.log(e)
  }
  return [data, weeklyData]
}

/**
 * Get and format transactions for global page
 */
const getGlobalTransactions = async () => {
  let transactions = {}

  try {
    let result = await queryClient({
      query: GLOBAL_TXNS,
      fetchPolicy: 'cache-first',
    })
    transactions.mints = []
    transactions.burns = []
    transactions.swaps = []
    result?.data?.transactions &&
      result.data.transactions.map((transaction) => {
        if (transaction.mints.length > 0) {
          transaction.mints.map((mint) => {
            return transactions.mints.push(mint)
          })
        }
        if (transaction.burns.length > 0) {
          transaction.burns.map((burn) => {
            return transactions.burns.push(burn)
          })
        }
        if (transaction.swaps.length > 0) {
          transaction.swaps.map((swap) => {
            return transactions.swaps.push(swap)
          })
        }
        return true
      })
  } catch (e) {
    console.log(e)
  }

  return transactions
}

/**
 * Gets the current price  of ETH, 24 hour price, and % change between them
 */
const getEthPrice = async () => {
  const utcCurrentTime = dayjs()
  const utcOneDayBack = utcCurrentTime.subtract(1, 'day').startOf('minute').unix()

  let ethPrice = 0
  let ethPriceOneDay = 0
  let priceChangeETH = 0

  try {
    let oneDayBlock = await getBlockFromTimestamp(utcOneDayBack)
    let result = await queryClient({
      query: ETH_PRICE(),
      fetchPolicy: 'cache-first',
    })
    let resultOneDay = await queryClient({
      query: ETH_PRICE(oneDayBlock),
      fetchPolicy: 'cache-first',
    })
    const currentPrice = result?.data?.bundles[0]?.ethPrice
    const oneDayBackPrice = resultOneDay?.data?.bundles[0]?.ethPrice
    priceChangeETH = getPercentChange(currentPrice, oneDayBackPrice)
    ethPrice = currentPrice
    ethPriceOneDay = oneDayBackPrice
  } catch (e) {
    console.log(e)
  }

  return [ethPrice, ethPriceOneDay, priceChangeETH]
}

// const PAIRS_TO_FETCH = 500
const TOKENS_TO_FETCH = 500

/**
 * Loop through every pair on uniswap, used for search
 */
async function getAllPairsOnUniswap() {
  const { pools, pairsTotalInfo } = await apiFetchAllPools();
  return { pools, pairsTotalInfo };
}

/**
 * Loop through every token on uniswap, used for search
 */
async function getAllTokensOnUniswap() {
  try {
    let allFound = false
    let skipCount = 0
    let tokens = []
    while (!allFound) {
      let result = await queryClient({
        query: ALL_TOKENS,
        variables: {
          skip: skipCount,
        },
        fetchPolicy: 'cache-first',
      })
      tokens = tokens.concat(result?.data?.tokens)
      if (result?.data?.tokens?.length < TOKENS_TO_FETCH || tokens.length > TOKENS_TO_FETCH) {
        allFound = true
      }
      skipCount = skipCount += TOKENS_TO_FETCH
    }
    return tokens
  } catch (e) {
    console.log(e)
  }
}

/**
 * Hook that fetches overview data, plus all tokens and pairs for search
 */
export function useGlobalData() {
  const [state, setState] = useGlobalDataContext()
  const { update, updateAllPairsInUniswap, updateAllTokensInUniswap } = setState;
  const [ethPrice, oldEthPrice] = useEthPrice()
  const [, , currentPoolVal] = useCurrentPool()
  const data = state?.globalData
  const pairsTotalInfo = state?.pairsTotalInfo;
  // const combinedVolume = useTokenDataCombined(offsetVolumes)

  useEffect(() => {
    async function fetchData() {
      //console.log('fetchData: enter');
      //let globalData = await getGlobalData(ethPrice, oldEthPrice)
      getGlobalData(ethPrice, oldEthPrice).then(globalData => globalData && update(globalData))
      //console.log('fetchData: globalData', globalData);

      //globalData && update(globalData)
      //console.log('fetchData: globalData after update', globalData);

      //let allPairs = await getAllPairsOnUniswap()
      //updateAllPairsInUniswap(allPairs)
      getAllPairsOnUniswap().then(pairsData => updateAllPairsInUniswap(pairsData))
      //console.log('fetchData: allPairs', allPairs);

      //let allTokens = await getAllTokensOnUniswap()
      //updateAllTokensInUniswap(allTokens)
      getAllTokensOnUniswap().then(allTokens => updateAllTokensInUniswap(allTokens))
      //console.log('fetchData: allTokens', allTokens);
    }
    if (!data && ethPrice && oldEthPrice) {
      //console.log('fetchData: fetchData');
      fetchData()
    }
  }, [ethPrice, oldEthPrice, update, data, updateAllPairsInUniswap, updateAllTokensInUniswap])

  if (data?.length && pairsTotalInfo?.length) {

    return {
      ...data[currentPoolVal],
      ...pairsTotalInfo[currentPoolVal]
    }
  }
  return {}
}

export function useGlobalChartData() {
  //console.log('useGlobalChartData');
  const [state, { updateChart }] = useGlobalDataContext()
  const [oldestDateFetch, setOldestDateFetched] = useState()
  const [activeWindow] = useTimeframe()

  const chartDataDaily = state?.chartData?.daily
  const chartDataWeekly = state?.chartData?.weekly

  /**
   * Keep track of oldest date fetched. Used to
   * limit data fetched until its actually needed.
   * (dont fetch year long stuff unless year option selected)
   */
  useEffect(() => {
    // based on window, get starttime
    let startTime = getTimeframe(activeWindow)

    if ((activeWindow && startTime < oldestDateFetch) || !oldestDateFetch) {
      setOldestDateFetched(startTime)
    }
  }, [activeWindow, oldestDateFetch])

  // fix for rebass tokens
  const combinedData = useTokenChartDataCombined(offsetVolumes)

  /**
   * Fetch data if none fetched or older data is needed
   */
  useEffect(() => {
    async function fetchData() {
      //console.log('useGlobalChartData fetchData');
      // historical stuff for chart
      let [newChartData, newWeeklyData] = await getChartData(oldestDateFetch, combinedData)
      //console.log('useGlobalChartData updateChart', newChartData);
      updateChart(newChartData, newWeeklyData)
    }
    if (oldestDateFetch && !(chartDataDaily && chartDataWeekly) && combinedData) {
      fetchData()
    }
  }, [chartDataDaily, chartDataWeekly, combinedData, oldestDateFetch, updateChart])

  //console.log('chartDataDaily', chartDataDaily);
  return [chartDataDaily, chartDataWeekly]
}

export function useGlobalTransactions() {
  const [state, { updateTransactions }] = useGlobalDataContext()
  const transactions = state?.transactions
  useEffect(() => {
    async function fetchData() {
      if (!transactions) {
        let txns = await getGlobalTransactions()
        updateTransactions(txns)
      }
    }
    fetchData()
  }, [updateTransactions, transactions])
  return transactions
}

export function useEthPrice() {
  const [state, { updateEthPrice }] = useGlobalDataContext()
  const ethPrice = state?.[ETH_PRICE_KEY]
  const ethPriceOld = state?.['oneDayPrice']
  useEffect(() => {
    async function checkForEthPrice() {
      if (!ethPrice) {
        let [newPrice, oneDayPrice, priceChange] = await getEthPrice()
        updateEthPrice(newPrice, oneDayPrice, priceChange)
      }
    }
    checkForEthPrice()
  }, [ethPrice, updateEthPrice])

  return [ethPrice, ethPriceOld]
}

export function useAllPairsInUniswap() {
  const [state] = useGlobalDataContext()
  let allPairs = state?.allPairs

  return allPairs || []
}

export function useAllTokensInUniswap() {
  const [state] = useGlobalDataContext()
  let allTokens = state?.allTokens

  return allTokens || []
}

export function useCurrentPool() {
  const [state, { updatePool }] = useGlobalDataContext()
  useEffect(() => {
    !state.currentPool && updatePool('All')
  })
  const PoolEnum = Object.freeze({
    all: 2,
    'classic pool': 0,
    'stable pool': 1
  })
  return [state.currentPool, updatePool, PoolEnum[state.currentPool?.toLowerCase()]]
}

/**
 * Get the top liquidity positions based on USD size
 * @TODO Not a perfect lookup needs improvement
 */
export function useTopLps() {
  const [state, { updateTopLps }] = useGlobalDataContext()
  const [tokenData] = useTokenDataContext();

  let topLps = state?.topLps
  const allPairs = useAllPairData()

  useEffect(() => {
    async function fetchData() {
      // get top 20 by reserves
      let topPairs = Object.keys(allPairs)
        ?.sort((a, b) => parseFloat(allPairs[a].trackedReserveUSD > allPairs[b].trackedReserveUSD ? -1 : 1))
        ?.slice(0, 99)
        .map((pair) => pair)

      let topLpLists = await Promise.all(
        topPairs.map(async (pair) => {
          // for each one, fetch top LPs
          try {
            const { data: results } = await queryClient({
              query: TOP_LPS_PER_PAIRS,
              variables: {
                pair: pair.toString(),
              },
              fetchPolicy: 'cache-first',
            })
            if (results) {
              return results.liquidityPositions
            }
          } catch (e) { }
        })
      )

      // get the top lps from the results formatted
      const topLps = []

      topLpLists
        .filter((i) => !!i) // check for ones not fetched correctly
        .map((list) => {
          return list.map((entry) => {
            const pairData = allPairs[entry.pair.id]
            let totalSupply = pairData.totalSupply;
            if (typeof totalSupply === 'object') {
              totalSupply = pairData.totalSupply.div(1e12).toNumber()
            } else {
              totalSupply *= 1e-12;
            }
            return topLps.push({
              user: entry.user,
              pairName: pairData.token0.symbol + '-' + pairData.token1.symbol,
              pairAddress: entry.pair.id,
              token0: pairData.token0.id,
              token1: pairData.token1.id,
              usd:
                (parseFloat(entry.liquidityTokenBalance) / parseFloat(totalSupply)) *
                parseFloat(pairData.trackedReserveUSD),
            })
          })
        })

      const sorted = topLps.sort((a, b) => (a.usd > b.usd ? -1 : 1))
      const shorter = sorted.splice(0, 100)
      updateTopLps(shorter)
    }

    if (!topLps && allPairs && Object.keys(allPairs).length > 0 && tokenData && Object.keys(tokenData).length > 0) {
      fetchData()
    }
  })

  return topLps
}
