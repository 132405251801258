import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Box } from 'rebass'
import styled from 'styled-components'

import { AutoRow, RowBetween } from '../components/Row'
import { AutoColumn } from '../components/Column'
import PairList from '../components/PairList'
import TopTokenList from '../components/TokenList'
import TxnList from '../components/TxnList'
import GlobalChart from '../components/GlobalChart'
import Search from '../components/Search'
import GlobalStats from '../components/GlobalStats'

import { useGlobalData, useGlobalTransactions } from '../contexts/GlobalData'
import { useAllPairData } from '../contexts/PairData'
import { useMedia } from 'react-use'
import Panel from '../components/Panel'
import { useAllTokenData } from '../contexts/TokenData'
import { formattedNum, formattedPercent } from '../utils'
import { TYPE, ThemedBackground } from '../Theme'
import { transparentize } from 'polished'
import { CustomLink } from '../components/Link'

import { PageWrapper, ContentWrapper } from '../components'
import QuestionHelper from '../components/QuestionHelper'
import ContentLoader from "react-content-loader";
import SelectPool from '../components/SelectPool'

const ListOptions = styled(AutoRow)`
  height: 40px;
  width: 100%;
  font-size: 1.25rem;
  font-weight: 600;

  @media screen and (max-width: 640px) {
    font-size: 1rem;
  }
`

const GridRow = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  column-gap: 6px;
  align-items: start;
  justify-content: space-between;
`

function GlobalPage() {
  const globalData = useGlobalData()
  // get data for lists and totals
  const allPairs = useAllPairData()
  const allTokens = useAllTokenData()
  //console.log('GlobalPage allPairs', allPairs);
  //console.log('GlobalPage allTokens', allTokens);
  const transactions = useGlobalTransactions()
  //console.log('GlobalPage transactions', transactions);

  const { totalLiquidityUSD, oneDayVolumeUSD, volumeChangeUSD, liquidityChangeUSD } = globalData
  //console.log('GlobalPage useGlobalData', totalLiquidityUSD, oneDayVolumeUSD, volumeChangeUSD, liquidityChangeUSD);

  // breakpoints
  const below800 = useMedia('(max-width: 800px)')

  // scrolling refs
  useEffect(() => {
    document.querySelector('body').scrollTo({
      behavior: 'smooth',
      top: 0,
    })
  }, [])

  // for tracked data on pairs
  const [useTracked] = useState(true)
  const dye = '#4E529A';

  return (
    <PageWrapper>
      <ThemedBackground backgroundColor={transparentize(0.6, dye)} />
      <ContentWrapper>
        <div>
          <AutoColumn style={{ paddingBottom: below800 ? '16px' : '24px' }}>
            <TYPE.header>{below800 ? '' : ''}</TYPE.header>
            <Search />
            <RowBetween style={{ margin: "24px 0 24px 0" }}>
              <TYPE.main fontSize={'1.125rem'} style={{ whiteSpace: 'nowrap' }}>
                Overview
              </TYPE.main>
              <SelectPool />
            </RowBetween>
            <AutoRow>

            </AutoRow>
            <GlobalStats />
          </AutoColumn>
          {below800 && ( // mobile card
            <Box mb={20}>
              <Panel>
                <Box>
                  <AutoColumn gap="36px">
                    <AutoColumn gap="20px">
                      <RowBetween>
                        <TYPE.main>Volume (24h)</TYPE.main>
                        <div />
                      </RowBetween>
                      <RowBetween align="flex-end">
                        <TYPE.main fontSize={'1.5rem'} lineHeight={1} fontWeight={600}>
                          {oneDayVolumeUSD ? formattedNum(oneDayVolumeUSD, true) : '-'}
                        </TYPE.main>
                        <TYPE.main fontSize={12}>{volumeChangeUSD ? formattedPercent(volumeChangeUSD) : '-'}</TYPE.main>
                      </RowBetween>
                    </AutoColumn>
                    <AutoColumn gap="20px">
                      <RowBetween>
                        <TYPE.main>Liquidity</TYPE.main>
                        <div />
                      </RowBetween>
                      <RowBetween align="flex-end">
                        <TYPE.main fontSize={'1.5rem'} lineHeight={1} fontWeight={600}>
                          {totalLiquidityUSD ? formattedNum(totalLiquidityUSD, true) : '-'}
                        </TYPE.main>
                        <TYPE.main fontSize={12}>
                          {liquidityChangeUSD ? formattedPercent(liquidityChangeUSD) : '-'}
                        </TYPE.main>
                      </RowBetween>
                    </AutoColumn>
                  </AutoColumn>
                </Box>
              </Panel>
            </Box>
          )}
          {!below800 && (
            <GridRow>
              <Panel style={{ height: '100%', minHeight: '300px' }}>
                {Object.keys(globalData).length > 0 ?
                  <GlobalChart display="liquidity" /> :
                  <ContentLoader viewBox="0 0 685 300" height={300} width='100%' backgroundColor="#4e529a" foregroundColor="#29245c" style={{ opacity: 0.4 }}>
                    <rect x="20" y="280" rx="0" ry="0" width="517" height="1" />
                    <rect x="17" y="27" rx="0" ry="0" width="103" height="21" />
                    <rect x="17" y="7" rx="0" ry="0" width="67" height="15" />
                    <rect x="550" y="68" rx="0" ry="0" width="75" height="8" />
                    <rect x="550" y="118" rx="0" ry="0" width="75" height="8" />
                    <rect x="550" y="168" rx="0" ry="0" width="75" height="8" />
                    <rect x="550" y="218" rx="0" ry="0" width="75" height="8" />
                    <rect x="550" y="268" rx="0" ry="0" width="75" height="8" />
                    <rect x="20" y="160" rx="0" ry="0" width="39" height="120" />
                    <rect x="60" y="180" rx="0" ry="0" width="39" height="100" />
                    <rect x="100" y="230" rx="0" ry="0" width="39" height="50" />
                    <rect x="140" y="210" rx="0" ry="0" width="39" height="70" />
                    <rect x="180" y="140" rx="0" ry="0" width="39" height="140" />
                    <rect x="220" y="60" rx="0" ry="0" width="39" height="220" />
                    <rect x="260" y="110" rx="0" ry="0" width="39" height="170" />
                    <rect x="300" y="80" rx="0" ry="0" width="39" height="200" />
                    <rect x="340" y="70" rx="0" ry="0" width="39" height="210" />
                    <rect x="380" y="60" rx="0" ry="0" width="39" height="220" />
                    <rect x="420" y="140" rx="0" ry="0" width="39" height="140" />
                    <rect x="460" y="160" rx="0" ry="0" width="39" height="120" />
                    <rect x="500" y="120" rx="0" ry="0" width="39" height="160" />
                  </ContentLoader>
                }
              </Panel>
              <Panel style={{ height: '100%' }}>
                {Object.keys(globalData).length > 0 ?
                  <GlobalChart display="volume" /> :
                  <ContentLoader viewBox="0 0 685 300" height={300} width='100%' backgroundColor="#4e529a" foregroundColor="#29245c" style={{ 'opacity': 0.4 }}>
                    <rect x="20" y="280" rx="0" ry="0" width="517" height="1" />
                    <rect x="17" y="27" rx="0" ry="0" width="103" height="21" />
                    <rect x="17" y="7" rx="0" ry="0" width="67" height="15" />
                    <rect x="550" y="68" rx="0" ry="0" width="75" height="8" />
                    <rect x="550" y="118" rx="0" ry="0" width="75" height="8" />
                    <rect x="550" y="168" rx="0" ry="0" width="75" height="8" />
                    <rect x="550" y="218" rx="0" ry="0" width="75" height="8" />
                    <rect x="550" y="268" rx="0" ry="0" width="75" height="8" />
                    <rect x="20" y="160" rx="0" ry="0" width="39" height="120" />
                    <rect x="60" y="180" rx="0" ry="0" width="39" height="100" />
                    <rect x="100" y="230" rx="0" ry="0" width="39" height="50" />
                    <rect x="140" y="210" rx="0" ry="0" width="39" height="70" />
                    <rect x="180" y="140" rx="0" ry="0" width="39" height="140" />
                    <rect x="220" y="60" rx="0" ry="0" width="39" height="220" />
                    <rect x="260" y="110" rx="0" ry="0" width="39" height="170" />
                    <rect x="300" y="80" rx="0" ry="0" width="39" height="200" />
                    <rect x="340" y="70" rx="0" ry="0" width="39" height="210" />
                    <rect x="380" y="60" rx="0" ry="0" width="39" height="220" />
                    <rect x="420" y="140" rx="0" ry="0" width="39" height="140" />
                    <rect x="460" y="160" rx="0" ry="0" width="39" height="120" />
                    <rect x="500" y="120" rx="0" ry="0" width="39" height="160" />
                  </ContentLoader>
                }
              </Panel>
            </GridRow>
          )}
          {below800 && (
            <AutoColumn style={{ marginTop: '6px' }} gap="24px">
              <Panel style={{ height: '100%', minHeight: '300px' }}>
                <GlobalChart display="liquidity" />
              </Panel>
            </AutoColumn>
          )}

          <ListOptions gap="10px" style={{ marginTop: '2rem', marginBottom: '.5rem' }}>
            <RowBetween>
              <TYPE.main fontSize={'1.125rem'} style={{ whiteSpace: 'nowrap' }}>
                Top Tokens
              </TYPE.main>
              <CustomLink to={'/tokens'}>Explore All</CustomLink>
            </RowBetween>
          </ListOptions>
          <Panel style={{ marginTop: '6px', padding: '1.125rem 0 ' }}>
            {Object.keys(allTokens).length > 0 ?
              <TopTokenList tokens={allTokens} /> :
              <ContentLoader viewBox="0 0 1376 633" height={633} width='100%' backgroundColor="#4e529a" foregroundColor="#29245c" style={{ 'opacity': 0.4 }}>
                <rect x="114" y="52" rx="6" ry="6" width="100" height="15" />
                <rect x="500" y="52" rx="6" ry="6" width="100" height="15" />
                <rect x="900" y="52" rx="6" ry="6" width="100" height="15" />
                <rect x="1250" y="52" rx="6" ry="6" width="100" height="15" />
                <circle cx="77" cy="60" r="15" />
                <rect x="116" y="105" rx="6" ry="6" width="100" height="15" />
                <rect x="500" y="105" rx="6" ry="6" width="130" height="15" />
                <rect x="900" y="105" rx="6" ry="6" width="120" height="15" />
                <rect x="1250" y="105" rx="6" ry="6" width="100" height="15" />
                <circle cx="78" cy="113" r="15" />
                <rect x="115" y="158" rx="6" ry="6" width="120" height="15" />
                <rect x="500" y="158" rx="6" ry="6" width="110" height="15" />
                <rect x="900" y="158" rx="6" ry="6" width="130" height="15" />
                <rect x="1250" y="158" rx="6" ry="6" width="100" height="15" />
                <circle cx="78" cy="166" r="15" />
                <rect x="117" y="211" rx="6" ry="6" width="110" height="15" />
                <rect x="500" y="211" rx="6" ry="6" width="100" height="15" />
                <rect x="900" y="211" rx="6" ry="6" width="100" height="15" />
                <rect x="1250" y="211" rx="6" ry="6" width="100" height="15" />
                <circle cx="79" cy="219" r="15" />
                <rect x="117" y="263" rx="6" ry="6" width="130" height="15" />
                <rect x="500" y="263" rx="6" ry="6" width="110" height="15" />
                <rect x="900" y="263" rx="6" ry="6" width="130" height="15" />
                <rect x="1250" y="263" rx="6" ry="6" width="100" height="15" />
                <circle cx="80" cy="271" r="15" />
                <rect x="114" y="315" rx="6" ry="6" width="100" height="15" />
                <rect x="500" y="315" rx="6" ry="6" width="100" height="15" />
                <rect x="900" y="315" rx="6" ry="6" width="100" height="15" />
                <rect x="1250" y="315" rx="6" ry="6" width="100" height="15" />
                <circle cx="77" cy="323" r="15" />
                <rect x="116" y="367" rx="6" ry="6" width="100" height="15" />
                <rect x="500" y="367" rx="6" ry="6" width="130" height="15" />
                <rect x="900" y="367" rx="6" ry="6" width="120" height="15" />
                <rect x="1250" y="367" rx="6" ry="6" width="100" height="15" />
                <circle cx="78" cy="375" r="15" />
                <rect x="115" y="419" rx="6" ry="6" width="120" height="15" />
                <rect x="500" y="419" rx="6" ry="6" width="110" height="15" />
                <rect x="900" y="419" rx="6" ry="6" width="130" height="15" />
                <rect x="1250" y="419" rx="6" ry="6" width="100" height="15" />
                <circle cx="78" cy="427" r="15" />
                <rect x="117" y="471" rx="6" ry="6" width="110" height="15" />
                <rect x="500" y="471" rx="6" ry="6" width="100" height="15" />
                <rect x="900" y="471" rx="6" ry="6" width="100" height="15" />
                <rect x="1250" y="471" rx="6" ry="6" width="100" height="15" />
                <circle cx="79" cy="479" r="15" />
                <rect x="117" y="523" rx="6" ry="6" width="130" height="15" />
                <rect x="500" y="523" rx="6" ry="6" width="110" height="15" />
                <rect x="900" y="523" rx="6" ry="6" width="130" height="15" />
                <rect x="1250" y="523" rx="6" ry="6" width="100" height="15" />
                <circle cx="80" cy="531" r="15" />
              </ContentLoader>
            }
          </Panel>

          <ListOptions gap="10px" style={{ marginTop: '2rem', marginBottom: '.5rem' }}>
            <RowBetween>
              <TYPE.main fontSize={'1rem'} style={{ whiteSpace: 'nowrap' }}>
                Top Pools
              </TYPE.main>
              <AutoRow gap="4px" width="100%" justifyContent="flex-end">
                <QuestionHelper text="USD amounts may be inaccurate in low liquidty pools or pools without ETH or stablecoins." />
                <CustomLink to={'/pairs'}>Explore All</CustomLink>
              </AutoRow>
            </RowBetween>
          </ListOptions>
          <Panel style={{ marginTop: '6px', padding: '1.125rem 0 ' }}>
            {Object.keys(allPairs).length > 0 ?
              <PairList pairs={allPairs} useTracked={useTracked} /> :
              <ContentLoader viewBox="0 0 1376 633" height={633} width='100%' backgroundColor="#4e529a" foregroundColor="#29245c" style={{ 'opacity': 0.4 }}>
                <rect x="114" y="52" rx="6" ry="6" width="100" height="15" />
                <rect x="500" y="52" rx="6" ry="6" width="100" height="15" />
                <rect x="900" y="52" rx="6" ry="6" width="100" height="15" />
                <rect x="1250" y="52" rx="6" ry="6" width="100" height="15" />
                <circle cx="77" cy="60" r="15" />
                <rect x="116" y="105" rx="6" ry="6" width="100" height="15" />
                <rect x="500" y="105" rx="6" ry="6" width="130" height="15" />
                <rect x="900" y="105" rx="6" ry="6" width="120" height="15" />
                <rect x="1250" y="105" rx="6" ry="6" width="100" height="15" />
                <circle cx="78" cy="113" r="15" />
                <rect x="115" y="158" rx="6" ry="6" width="120" height="15" />
                <rect x="500" y="158" rx="6" ry="6" width="110" height="15" />
                <rect x="900" y="158" rx="6" ry="6" width="130" height="15" />
                <rect x="1250" y="158" rx="6" ry="6" width="100" height="15" />
                <circle cx="78" cy="166" r="15" />
                <rect x="117" y="211" rx="6" ry="6" width="110" height="15" />
                <rect x="500" y="211" rx="6" ry="6" width="100" height="15" />
                <rect x="900" y="211" rx="6" ry="6" width="100" height="15" />
                <rect x="1250" y="211" rx="6" ry="6" width="100" height="15" />
                <circle cx="79" cy="219" r="15" />
                <rect x="117" y="263" rx="6" ry="6" width="130" height="15" />
                <rect x="500" y="263" rx="6" ry="6" width="110" height="15" />
                <rect x="900" y="263" rx="6" ry="6" width="130" height="15" />
                <rect x="1250" y="263" rx="6" ry="6" width="100" height="15" />
                <circle cx="80" cy="271" r="15" />
                <rect x="114" y="315" rx="6" ry="6" width="100" height="15" />
                <rect x="500" y="315" rx="6" ry="6" width="100" height="15" />
                <rect x="900" y="315" rx="6" ry="6" width="100" height="15" />
                <rect x="1250" y="315" rx="6" ry="6" width="100" height="15" />
                <circle cx="77" cy="323" r="15" />
                <rect x="116" y="367" rx="6" ry="6" width="100" height="15" />
                <rect x="500" y="367" rx="6" ry="6" width="130" height="15" />
                <rect x="900" y="367" rx="6" ry="6" width="120" height="15" />
                <rect x="1250" y="367" rx="6" ry="6" width="100" height="15" />
                <circle cx="78" cy="375" r="15" />
                <rect x="115" y="419" rx="6" ry="6" width="120" height="15" />
                <rect x="500" y="419" rx="6" ry="6" width="110" height="15" />
                <rect x="900" y="419" rx="6" ry="6" width="130" height="15" />
                <rect x="1250" y="419" rx="6" ry="6" width="100" height="15" />
                <circle cx="78" cy="427" r="15" />
                <rect x="117" y="471" rx="6" ry="6" width="110" height="15" />
                <rect x="500" y="471" rx="6" ry="6" width="100" height="15" />
                <rect x="900" y="471" rx="6" ry="6" width="100" height="15" />
                <rect x="1250" y="471" rx="6" ry="6" width="100" height="15" />
                <circle cx="79" cy="479" r="15" />
                <rect x="117" y="523" rx="6" ry="6" width="130" height="15" />
                <rect x="500" y="523" rx="6" ry="6" width="110" height="15" />
                <rect x="900" y="523" rx="6" ry="6" width="130" height="15" />
                <rect x="1250" y="523" rx="6" ry="6" width="100" height="15" />
                <circle cx="80" cy="531" r="15" />
              </ContentLoader>
            }
          </Panel>

          <span>
            <TYPE.main fontSize={'1.125rem'} style={{ marginTop: '2rem' }}>
              Recent Transactions
            </TYPE.main>
          </span>
          <Panel style={{ margin: '1rem 0' }}>
            {transactions ?
              <TxnList transactions={transactions} /> :
              <ContentLoader viewBox="0 0 1376 633" height={633} width='100%' backgroundColor="#4e529a" foregroundColor="#29245c" style={{ 'opacity': 0.4 }}>
                <rect x="114" y="52" rx="6" ry="6" width="100" height="15" />
                <rect x="500" y="52" rx="6" ry="6" width="100" height="15" />
                <rect x="900" y="52" rx="6" ry="6" width="100" height="15" />
                <rect x="1250" y="52" rx="6" ry="6" width="100" height="15" />
                <circle cx="77" cy="60" r="15" />
                <rect x="116" y="105" rx="6" ry="6" width="100" height="15" />
                <rect x="500" y="105" rx="6" ry="6" width="130" height="15" />
                <rect x="900" y="105" rx="6" ry="6" width="120" height="15" />
                <rect x="1250" y="105" rx="6" ry="6" width="100" height="15" />
                <circle cx="78" cy="113" r="15" />
                <rect x="115" y="158" rx="6" ry="6" width="120" height="15" />
                <rect x="500" y="158" rx="6" ry="6" width="110" height="15" />
                <rect x="900" y="158" rx="6" ry="6" width="130" height="15" />
                <rect x="1250" y="158" rx="6" ry="6" width="100" height="15" />
                <circle cx="78" cy="166" r="15" />
                <rect x="117" y="211" rx="6" ry="6" width="110" height="15" />
                <rect x="500" y="211" rx="6" ry="6" width="100" height="15" />
                <rect x="900" y="211" rx="6" ry="6" width="100" height="15" />
                <rect x="1250" y="211" rx="6" ry="6" width="100" height="15" />
                <circle cx="79" cy="219" r="15" />
                <rect x="117" y="263" rx="6" ry="6" width="130" height="15" />
                <rect x="500" y="263" rx="6" ry="6" width="110" height="15" />
                <rect x="900" y="263" rx="6" ry="6" width="130" height="15" />
                <rect x="1250" y="263" rx="6" ry="6" width="100" height="15" />
                <circle cx="80" cy="271" r="15" />
                <rect x="114" y="315" rx="6" ry="6" width="100" height="15" />
                <rect x="500" y="315" rx="6" ry="6" width="100" height="15" />
                <rect x="900" y="315" rx="6" ry="6" width="100" height="15" />
                <rect x="1250" y="315" rx="6" ry="6" width="100" height="15" />
                <circle cx="77" cy="323" r="15" />
                <rect x="116" y="367" rx="6" ry="6" width="100" height="15" />
                <rect x="500" y="367" rx="6" ry="6" width="130" height="15" />
                <rect x="900" y="367" rx="6" ry="6" width="120" height="15" />
                <rect x="1250" y="367" rx="6" ry="6" width="100" height="15" />
                <circle cx="78" cy="375" r="15" />
                <rect x="115" y="419" rx="6" ry="6" width="120" height="15" />
                <rect x="500" y="419" rx="6" ry="6" width="110" height="15" />
                <rect x="900" y="419" rx="6" ry="6" width="130" height="15" />
                <rect x="1250" y="419" rx="6" ry="6" width="100" height="15" />
                <circle cx="78" cy="427" r="15" />
                <rect x="117" y="471" rx="6" ry="6" width="110" height="15" />
                <rect x="500" y="471" rx="6" ry="6" width="100" height="15" />
                <rect x="900" y="471" rx="6" ry="6" width="100" height="15" />
                <rect x="1250" y="471" rx="6" ry="6" width="100" height="15" />
                <circle cx="79" cy="479" r="15" />
                <rect x="117" y="523" rx="6" ry="6" width="130" height="15" />
                <rect x="500" y="523" rx="6" ry="6" width="110" height="15" />
                <rect x="900" y="523" rx="6" ry="6" width="130" height="15" />
                <rect x="1250" y="523" rx="6" ry="6" width="100" height="15" />
                <circle cx="80" cy="531" r="15" />
              </ContentLoader>
            }
          </Panel>
        </div>
      </ContentWrapper>
    </PageWrapper>
  )
}

export default withRouter(GlobalPage)
