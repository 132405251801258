import React, { useState } from 'react'
import styled from 'styled-components'
import { ApolloProvider } from 'react-apollo'
import { client } from './apollo/client'
import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom'
import GlobalPage from './pages/GlobalPage'
import TokenPage from './pages/TokenPage'
import PairPage from './pages/PairPage'
import { useGlobalData } from './contexts/GlobalData'
import { isAddress } from './utils'
import AccountPage from './pages/AccountPage'
import AllTokensPage from './pages/AllTokensPage'
import AllPairsPage from './pages/AllPairsPage'
// import PinnedData from './components/PinnedData'
import TopBar from './components/Header/TopBar'
import Header from './components/Header'
import AccountLookup from './pages/AccountLookup'
import LocalLoader from './components/LocalLoader'
import { useLatestBlocks } from './contexts/Application'
import { PAIR_BLACKLIST, TOKEN_BLACKLIST } from './constants'
import { NETWORK_NAME } from './utils/env'

const AppWrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`

const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  // width: 100vw;
  position: sticky;
  top: 0;
  left: 0;
  justify-content: space-between;
  z-index: 3;

  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  
  // background-color: #22242a;
`

const Hide1080 = styled.div`
  @media (max-width: 1080px) {
    display: none;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex:1;
  >div {
    flex: 1
  }
  
  z-index:2;
  position: relative;


  margin-top: ${({ warningActive }) => (warningActive ? '40px' : '0px')};

`

// const Right = styled.div`
//   position: fixed;
//   right: 0;
//   bottom: 0rem;
//   z-index: 99;
//   width: ${({ open }) => (open ? '220px' : '64px')};
//   height: ${({ open }) => (open ? 'fit-content' : '64px')};
//   overflow: auto;
//   background-color: ${({ theme }) => theme.bg1};
//   @media screen and (max-width: 1400px) {
//     display: none;
//   }
// `

const Center = styled.div`
  height: 100%;
  z-index: 9999;
  transition: width 0.25s ease;
  background-color: ${({ theme }) => theme.onlyLight};
`

const WarningWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const WarningBanner = styled.div`
  background-color: #ff6871;
  padding: 1.5rem;
  color: white;
  width: 100%;
  text-align: center;
  font-weight: 500;
`

/**
 * Wrap the component with the header and sidebar pinned tab
 */
const LayoutWrapper = ({ children, savedOpen, setSavedOpen }) => {
  return (
    <>
      <HeaderWrapper>
        <Hide1080>
          <TopBar />
        </Hide1080>
        <Header />
      </HeaderWrapper>
      <ContentWrapper open={savedOpen}>


        <Center id="center">{children}</Center>
        {/* <Right open={savedOpen}>
          <PinnedData open={savedOpen} setSavedOpen={setSavedOpen} />
        </Right> */}
      </ContentWrapper>
    </>
  )
}

const BLOCK_DIFFERENCE_THRESHOLD = 100

function App() {
  const [savedOpen, setSavedOpen] = useState(false)

  const globalData = useGlobalData()
  //const globalChartData = useGlobalChartData()
  const [latestBlock, headBlock] = useLatestBlocks()

  // show warning
  const showWarning = headBlock && latestBlock ? headBlock - latestBlock > BLOCK_DIFFERENCE_THRESHOLD : false

  //console.log('App load status', globalData, Object.keys(globalData).length, globalChartData, Object.keys(globalChartData).length);

  return (
    <ApolloProvider client={client}>
      <AppWrapper>
        {showWarning && (
          <WarningWrapper>
            <WarningBanner>
              {`The data on this site has only synced to ${NETWORK_NAME} block ${latestBlock} (out of ${headBlock}). Please check back soon.`}
            </WarningBanner>
          </WarningWrapper>
        )}
        {globalData
          ? (
            <BrowserRouter>
              {/*<Route component={GoogleAnalyticsReporter} />*/}
              <Switch>
                <Route
                  exacts
                  strict
                  path="/token/:tokenAddress"
                  render={({ match }) => {
                    if (
                      isAddress(match.params.tokenAddress.toLowerCase()) &&
                      !Object.keys(TOKEN_BLACKLIST).includes(match.params.tokenAddress.toLowerCase())
                    ) {
                      return (
                        <LayoutWrapper savedOpen={savedOpen} setSavedOpen={setSavedOpen}>
                          <TokenPage address={match.params.tokenAddress.toLowerCase()} />
                        </LayoutWrapper>
                      )
                    } else {
                      return <Redirect to="/home" />
                    }
                  }}
                />
                <Route
                  exacts
                  strict
                  path="/pair/:pairAddress"
                  render={({ match }) => {
                    if (
                      isAddress(match.params.pairAddress.toLowerCase()) &&
                      !Object.keys(PAIR_BLACKLIST).includes(match.params.pairAddress.toLowerCase())
                    ) {
                      return (
                        <LayoutWrapper savedOpen={savedOpen} setSavedOpen={setSavedOpen}>
                          <PairPage pairAddress={match.params.pairAddress.toLowerCase()} />
                        </LayoutWrapper>
                      )
                    } else {
                      return <Redirect to="/home" />
                    }
                  }}
                />
                <Route
                  exacts
                  strict
                  path="/account/:accountAddress"
                  render={({ match }) => {
                    if (isAddress(match.params.accountAddress.toLowerCase())) {
                      return (
                        <LayoutWrapper savedOpen={savedOpen} setSavedOpen={setSavedOpen}>
                          <AccountPage account={match.params.accountAddress.toLowerCase()} />
                        </LayoutWrapper>
                      )
                    } else {
                      return <Redirect to="/home" />
                    }
                  }}
                />

                <Route path="/home">
                  <LayoutWrapper savedOpen={savedOpen} setSavedOpen={setSavedOpen}>
                    <GlobalPage />
                  </LayoutWrapper>
                </Route>

                <Route path="/tokens">
                  <LayoutWrapper savedOpen={savedOpen} setSavedOpen={setSavedOpen}>
                    <AllTokensPage />
                  </LayoutWrapper>
                </Route>

                <Route path="/pairs">
                  <LayoutWrapper savedOpen={savedOpen} setSavedOpen={setSavedOpen}>
                    <AllPairsPage />
                  </LayoutWrapper>
                </Route>

                <Route path="/accounts">
                  <LayoutWrapper savedOpen={savedOpen} setSavedOpen={setSavedOpen}>
                    <AccountLookup />
                  </LayoutWrapper>
                </Route>

                <Redirect to="/home" />
              </Switch>
            </BrowserRouter>
          ) : (
            <div style={{
              //background: 'rgb(31,34,63)',
              background: 'radial-gradient(circle, rgba(31,34,63,1) 0%, rgba(33,35,47,1) 100%)',
            }}>
              <LocalLoader fill="true" />
            </div>
          )}
      </AppWrapper>
    </ApolloProvider>
  )
}

export default App
