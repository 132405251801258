import React, { useEffect, useState } from 'react'
import 'feather-icons'

import { TYPE } from '../Theme'
import Panel from '../components/Panel'
import { useAllPairData } from '../contexts/PairData'
import PairList from '../components/PairList'
import { PageWrapper, FullWrapper } from '../components'
import {
  RowBetween,
} from '../components/Row'
import Search from '../components/Search'
import { useMedia } from 'react-use'
import { useSavedPairs } from '../contexts/LocalStorage'

import ContentLoader from "react-content-loader";

function AllPairsPage() {
  const allPairs = useAllPairData()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const below800 = useMedia('(max-width: 800px)')

  const [
    useTracked,
  ] = useState(true)

  const savedPairsData = [];
  const [savedPairs,] = useSavedPairs();
  for (const pairAddress of Object.keys(savedPairs)) {
    if (savedPairs[pairAddress]?.address) {
      const data = allPairs[pairAddress];
      if (data) {
        savedPairsData.push(data);
      }
    }
  }

  return (
    <PageWrapper>
      <FullWrapper>
        <RowBetween>
          <TYPE.title>Your Watchlist</TYPE.title>
        </RowBetween>
        {savedPairsData.length !== 0 && (
          <Panel style={{ padding: below800 && '1rem 0 0 0 ' }}>
            <PairList pairs={savedPairsData} disbaleLinks={true} maxItems={50} useTracked={false} />
          </Panel>
        )}
        {savedPairsData.length === 0 && (
          <Panel>
            <TYPE.light>Saved pools will appear here</TYPE.light>
          </Panel>
        )}

        <RowBetween>
          <TYPE.title>All Pools</TYPE.title>
          {!below800 && <Search small={true} />}
        </RowBetween>
        
        <Panel style={{ padding: below800 && '1rem 0 0 0 ' }}>
          {Object.keys(allPairs).length > 0 ?
            <PairList pairs={allPairs} disbaleLinks={true} maxItems={200} useTracked={useTracked} /> :
            <ContentLoader viewBox="0 0 1376 633" height={633} width='100%' backgroundColor="#4e529a" foregroundColor="#29245c" style={{ 'opacity': 0.4 }}>
              <rect x="114" y="52" rx="6" ry="6" width="100" height="15" />
              <rect x="500" y="52" rx="6" ry="6" width="100" height="15" />
              <rect x="900" y="52" rx="6" ry="6" width="100" height="15" />
              <rect x="1250" y="52" rx="6" ry="6" width="100" height="15" />
              <circle cx="77" cy="60" r="15" />
              <rect x="116" y="105" rx="6" ry="6" width="100" height="15" />
              <rect x="500" y="105" rx="6" ry="6" width="130" height="15" />
              <rect x="900" y="105" rx="6" ry="6" width="120" height="15" />
              <rect x="1250" y="105" rx="6" ry="6" width="100" height="15" />
              <circle cx="78" cy="113" r="15" />
              <rect x="115" y="158" rx="6" ry="6" width="120" height="15" />
              <rect x="500" y="158" rx="6" ry="6" width="110" height="15" />
              <rect x="900" y="158" rx="6" ry="6" width="130" height="15" />
              <rect x="1250" y="158" rx="6" ry="6" width="100" height="15" />
              <circle cx="78" cy="166" r="15" />
              <rect x="117" y="211" rx="6" ry="6" width="110" height="15" />
              <rect x="500" y="211" rx="6" ry="6" width="100" height="15" />
              <rect x="900" y="211" rx="6" ry="6" width="100" height="15" />
              <rect x="1250" y="211" rx="6" ry="6" width="100" height="15" />
              <circle cx="79" cy="219" r="15" />
              <rect x="117" y="263" rx="6" ry="6" width="130" height="15" />
              <rect x="500" y="263" rx="6" ry="6" width="110" height="15" />
              <rect x="900" y="263" rx="6" ry="6" width="130" height="15" />
              <rect x="1250" y="263" rx="6" ry="6" width="100" height="15" />
              <circle cx="80" cy="271" r="15" />
              <rect x="114" y="315" rx="6" ry="6" width="100" height="15" />
              <rect x="500" y="315" rx="6" ry="6" width="100" height="15" />
              <rect x="900" y="315" rx="6" ry="6" width="100" height="15" />
              <rect x="1250" y="315" rx="6" ry="6" width="100" height="15" />
              <circle cx="77" cy="323" r="15" />
              <rect x="116" y="367" rx="6" ry="6" width="100" height="15" />
              <rect x="500" y="367" rx="6" ry="6" width="130" height="15" />
              <rect x="900" y="367" rx="6" ry="6" width="120" height="15" />
              <rect x="1250" y="367" rx="6" ry="6" width="100" height="15" />
              <circle cx="78" cy="375" r="15" />
              <rect x="115" y="419" rx="6" ry="6" width="120" height="15" />
              <rect x="500" y="419" rx="6" ry="6" width="110" height="15" />
              <rect x="900" y="419" rx="6" ry="6" width="130" height="15" />
              <rect x="1250" y="419" rx="6" ry="6" width="100" height="15" />
              <circle cx="78" cy="427" r="15" />
              <rect x="117" y="471" rx="6" ry="6" width="110" height="15" />
              <rect x="500" y="471" rx="6" ry="6" width="100" height="15" />
              <rect x="900" y="471" rx="6" ry="6" width="100" height="15" />
              <rect x="1250" y="471" rx="6" ry="6" width="100" height="15" />
              <circle cx="79" cy="479" r="15" />
              <rect x="117" y="523" rx="6" ry="6" width="130" height="15" />
              <rect x="500" y="523" rx="6" ry="6" width="110" height="15" />
              <rect x="900" y="523" rx="6" ry="6" width="130" height="15" />
              <rect x="1250" y="523" rx="6" ry="6" width="100" height="15" />
              <circle cx="80" cy="531" r="15" />
            </ContentLoader>
          }
        </Panel>
      </FullWrapper>
    </PageWrapper>
  )
}

export default AllPairsPage
