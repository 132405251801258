import React, { useEffect } from 'react'
import 'feather-icons'

import TopTokenList from '../components/TokenList'
import { TYPE } from '../Theme'
import Panel from '../components/Panel'
import { useAllTokenData } from '../contexts/TokenData'
import { PageWrapper, FullWrapper } from '../components'
import { RowBetween } from '../components/Row'
import Search from '../components/Search'
import { useMedia } from 'react-use'
import { useSavedTokens } from '../contexts/LocalStorage'

import ContentLoader from "react-content-loader";

function AllTokensPage() {
  const allTokens = useAllTokenData()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const below600 = useMedia('(max-width: 800px)')

  const savedTokensData = [];
  const [savedTokens,] = useSavedTokens();
  for (const tokenAddress of Object.keys(savedTokens)) {
    if (savedTokens[tokenAddress]?.symbol) {
      const data = allTokens[tokenAddress];
      //console.log('data', data);
      if (data) {
        savedTokensData.push(data);
      }
    }
  }

  return (
    <PageWrapper>
      <FullWrapper>
        <RowBetween>
          <TYPE.title>Your Watchlist</TYPE.title>
        </RowBetween>
        {savedTokensData.length !== 0 && (
          <Panel style={{ marginTop: '6px', padding: below600 && '1rem 0 0 0 ' }}>
            <TopTokenList tokens={savedTokensData} itemMax={50} />
          </Panel>
        )}
        {savedTokensData.length === 0 && (
          <Panel>
            <TYPE.light>Saved tokens will appear here</TYPE.light>
          </Panel>
        )}

        <RowBetween>
          <TYPE.title>All Tokens</TYPE.title>
          {!below600 && <Search small={true} />}
        </RowBetween>
        <Panel style={{ marginTop: below600 ? '0' : '-0.5rem', padding: below600 && '1rem 0 0 0 ' }}>
          {Object.keys(allTokens).length > 0 ?
            <TopTokenList tokens={allTokens} itemMax={50} /> :
            <ContentLoader viewBox="0 0 1376 633" height={633} width='100%' backgroundColor="#4e529a" foregroundColor="#29245c" style={{ 'opacity': 0.4 }}>
              <rect x="114" y="52" rx="6" ry="6" width="100" height="15" />
              <rect x="500" y="52" rx="6" ry="6" width="100" height="15" />
              <rect x="900" y="52" rx="6" ry="6" width="100" height="15" />
              <rect x="1250" y="52" rx="6" ry="6" width="100" height="15" />
              <circle cx="77" cy="60" r="15" />
              <rect x="116" y="105" rx="6" ry="6" width="100" height="15" />
              <rect x="500" y="105" rx="6" ry="6" width="130" height="15" />
              <rect x="900" y="105" rx="6" ry="6" width="120" height="15" />
              <rect x="1250" y="105" rx="6" ry="6" width="100" height="15" />
              <circle cx="78" cy="113" r="15" />
              <rect x="115" y="158" rx="6" ry="6" width="120" height="15" />
              <rect x="500" y="158" rx="6" ry="6" width="110" height="15" />
              <rect x="900" y="158" rx="6" ry="6" width="130" height="15" />
              <rect x="1250" y="158" rx="6" ry="6" width="100" height="15" />
              <circle cx="78" cy="166" r="15" />
              <rect x="117" y="211" rx="6" ry="6" width="110" height="15" />
              <rect x="500" y="211" rx="6" ry="6" width="100" height="15" />
              <rect x="900" y="211" rx="6" ry="6" width="100" height="15" />
              <rect x="1250" y="211" rx="6" ry="6" width="100" height="15" />
              <circle cx="79" cy="219" r="15" />
              <rect x="117" y="263" rx="6" ry="6" width="130" height="15" />
              <rect x="500" y="263" rx="6" ry="6" width="110" height="15" />
              <rect x="900" y="263" rx="6" ry="6" width="130" height="15" />
              <rect x="1250" y="263" rx="6" ry="6" width="100" height="15" />
              <circle cx="80" cy="271" r="15" />
              <rect x="114" y="315" rx="6" ry="6" width="100" height="15" />
              <rect x="500" y="315" rx="6" ry="6" width="100" height="15" />
              <rect x="900" y="315" rx="6" ry="6" width="100" height="15" />
              <rect x="1250" y="315" rx="6" ry="6" width="100" height="15" />
              <circle cx="77" cy="323" r="15" />
              <rect x="116" y="367" rx="6" ry="6" width="100" height="15" />
              <rect x="500" y="367" rx="6" ry="6" width="130" height="15" />
              <rect x="900" y="367" rx="6" ry="6" width="120" height="15" />
              <rect x="1250" y="367" rx="6" ry="6" width="100" height="15" />
              <circle cx="78" cy="375" r="15" />
              <rect x="115" y="419" rx="6" ry="6" width="120" height="15" />
              <rect x="500" y="419" rx="6" ry="6" width="110" height="15" />
              <rect x="900" y="419" rx="6" ry="6" width="130" height="15" />
              <rect x="1250" y="419" rx="6" ry="6" width="100" height="15" />
              <circle cx="78" cy="427" r="15" />
              <rect x="117" y="471" rx="6" ry="6" width="110" height="15" />
              <rect x="500" y="471" rx="6" ry="6" width="100" height="15" />
              <rect x="900" y="471" rx="6" ry="6" width="100" height="15" />
              <rect x="1250" y="471" rx="6" ry="6" width="100" height="15" />
              <circle cx="79" cy="479" r="15" />
              <rect x="117" y="523" rx="6" ry="6" width="130" height="15" />
              <rect x="500" y="523" rx="6" ry="6" width="110" height="15" />
              <rect x="900" y="523" rx="6" ry="6" width="130" height="15" />
              <rect x="1250" y="523" rx="6" ry="6" width="100" height="15" />
              <circle cx="80" cy="531" r="15" />
            </ContentLoader>
          }
        </Panel>
      </FullWrapper>
    </PageWrapper>
  )
}

export default AllTokensPage
