import React from 'react';
// import Row from '../Row'
// import DropdownSelect from '../DropdownSelect'
import { OptionButton } from '../ButtonStyled'
import { TYPE } from '../../Theme'
import { RowFixed } from '../Row'
import { useCurrentPool } from '../../contexts/GlobalData';

const SelectPool = () => {
    // const dye = '#4E529A'
    const [currentPool, setCurrentPool] = useCurrentPool()

    const poolList = [
        'All', 'Classic Pool', 'Stable Pool'
    ]

    return (
        // Drop Select
        // <Row justifyContent="flex-end">
        //     <DropdownSelect style={{ minWidth: 150 }} color={dye} options={poolList} active={currentPool} setActive={setCurrentPool}></DropdownSelect>
        // </Row>

        // Buttons
        <RowFixed>
            {
                poolList.map(item => {
                    return <OptionButton
                        key={item}
                        style={{ marginLeft: 8 }}
                        active={currentPool === item}
                        onClick={() => setCurrentPool(item)}
                    >
                        <TYPE.body>{item}</TYPE.body>
                    </OptionButton>
                })
            }

        </RowFixed>
    )
}

export default SelectPool;